module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr","de","in","ar","es","fa","he","hu","it","pt","ro","tr","zh","ku","ru"],"defaultLanguage":"en","siteUrl":"https://*.tas.ink","i18nextOptions":{"defaultNS":"index","debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
