const prod = {
    url: {
        PUBLIC: 'https://public.takeaseat.io',
        API: 'https://api.takeaseat.io',
        BOOKING_API: 'https://booking-api.takeaseat.io'
    }
}

const dev = {
    url: {
        PUBLIC: 'http://localhost:3003',
        API: 'http://localhost:3002',
        BOOKING_API: 'https://booking-api.takeaseat.io'
    }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
